/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from 'react-helmet'
import PropTypes from "prop-types"

import { helmet } from '../../utils/helmet'
// import Header from "../header/header"
import Footer from "../footer/footer"
import "./theme.css"

const Layout = ({ children }) => {
  // const [isMenuActive, setMenuActive] = useState(false)

  // const bodyClass = isMenuActive ? 'lyt-Site is-iOS util-SiteOverflowed lyt-Drawer-open' : 'lyt-Site is-iOS'

  return (
    <>
      <Helmet {...helmet} />
      {/* <Header isMenuActive={isMenuActive} setMenuActive={setMenuActive} /> */}
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
