import React from "react"

import Img from "gatsby-image"

import "./hero.css"

const Hero = ({ image, subtitle, textcolor, title }) => (
  <section className={`sec-Hero sec-Hero-small sec-Hero-${textcolor}`}>
    <div className="sec-Hero_Image">
      <Img fluid={image} />
    </div>
    <div className="sec-Hero_Inner lyt-Container">
      <div className="sec-Hero_Body">
        <div className="sec-Hero_Content">
          <h2 className="sec-Hero_SubTitle">
            {subtitle}
          </h2>

          <h1 className="sec-Hero_Title">
            {title}
          </h1>
        </div>
      </div>
    </div>
  </section>
)

export default Hero
