import React from "react"

import "./footer.css"

const Footer = () => (
  <footer className="ft-Footer">
    <div className="ft-Footer_Inner lyt-Container">
      <div className="ft-Footer_Body">
        <div className="ft-Footer_Columns">
          <div className="ft-Footer_Column">
            <p>Have a project in mind?<br />
            Give us a call</p>

            <p><a className="ft-Footer_Link" href="tel:07879 339579" target="_blank" rel="noopener noreferrer">07879 339 579</a></p>
          </div>
          <div className="ft-Footer_Column">
            <p>Or drop us an email</p>

            <p><a className="ft-Footer_Link" href="mailto:lloyd@onpointcarpentryandjoinery.co.uk" target="_blank" rel="noopener noreferrer">lloyd@onpointcarpentryandjoinery.co.uk</a></p>
          </div>
        </div>
        <div className="ft-Footer_Rows">
          <div className="ft-Footer_Row">
            On Point Carpentry & Joinery © {new Date().getFullYear()}
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
